<template>
  <nav class="navbar has-background-dark">
    <div class="navbar-menu">
      <div class="navbar-brand">
        <a href="" class="navbar-item has-text-white-ter">DOWSCOPEMEDIA</a>
      </div>
      <div class="navbar-start">
        <router-link to="/" class="navbar-item has-text-primary">Home</router-link>
        <router-link to="/music" class="navbar-item has-text-primary">Music</router-link>
        <router-link to="/share" class="navbar-item has-text-primary">Share</router-link>
        <router-link to="/match" class="navbar-item has-text-primary">Match 3 Game</router-link>
        <router-link to="/rts" class="navbar-item has-text-primary">RTS Server</router-link>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: whitesmoke;
}
</style>
