import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "homeview" */ '../views/HomeView.vue'),
  },
  {
    path: '/music',
    name: 'music',
    component: () => import(/* webpackChunkName: "musicview" */ '../views/MusicView.vue'),
  },
  {
    path: '/share',
    name: 'share',
    component: () => import(/* webpackChunkName: "fileview" */ '../views/FileView.vue'),
  },
  {
    path: '/drop',
    name: 'drop',
    component: () => import(/* webpackChunkName: "dropview" */ '../views/DropView.vue'),
  },
  {
    path: '/match',
    name: 'match',
    component: () => import(/* webpackChunkName: "Match3Gameview" */ '../views/Match3GameView.vue'),
  },
  {
    path: '/rts',
    name: 'rts',
    component: () => import(/* webpackChunkName: "RtsView" */ '../views/RtsView.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
